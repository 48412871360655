/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        //Nav OnScroll
        $(window).scroll( function() {
          var navHeight = $(this).scrollTop();
          // var $toggleStatus = $(this).attr('aria-expanded').val();

          if (navHeight > 133 ) {
            $('.navbar-brand-inner').css('height', '129px');
          } else {
            $('.navbar-brand-inner').css('height', '150px');
          }
        });

        //Nav Toggle
        // $('.navbar-toggler').on('click', function(e){
        //   var $toggleStatus = $(this).attr('aria-expanded').val();
          
        //   if( $toggleStatus === true) {
        //     $('.navbar-brand-inner').css('height', '119px');
        //   } else {
        //     $('.navbar-brand-inner').css('height', '135px');
        //   }
        // });

        //Search Toggle
        $('.search-toggle').on('click', function(e) {
          e.preventDefault();
          
          var $button = $(this);
          var $searchbar = $('.search-bar');

          if( !$searchbar.is(":visible") ) {
            // if invisible we switch the icon to appear collapsable
            $('.navbar-brand-inner').css('height', '129px');
            $button.find('.search-minus-icon').removeClass('display-none').addClass('display-block');
            $button.find('.search-icon').removeClass('display-block').addClass('display-none');
          } else {
            // if visible we switch the icon to appear as a toggle
            $('.navbar-brand-inner').css('height', '150px');
            
            $button.find('.search-icon').removeClass('display-none').addClass('display-block');
            $button.find('.search-minus-icon').removeClass('display-block').addClass('display-none');
          }

          $searchbar.slideToggle(300, function () {
            // callback after search bar animation
          });

          $('#searchform').submit(function (e) {
            e.preventDefault(); // stop form submission
          });
        });

        //Form Label
        $('.gfield input').focus(function () {
          $(this).parents('.gfield').addClass('form-focused');
        });

        $('.gfield input').blur(function () {
          var inputValue = $(this).val();
          if (inputValue == "") {
            $(this).parents('.gfield').removeClass('form-focused');
          } 
        });

        $('.gfield_label').click(function (e) { 
          e.preventDefault(); // stop focus on input in first form
          $(this).next('.ginput_container').find('input').focus();
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        
        //SVG 
        //unfurl into inline from image
        $('img.svg').each(function () {
          var $img = $(this);
          var imgID = $img.attr('id');
          var imgClass = $img.attr('class');
          var imgURL = $img.attr('src');

          $.get(imgURL, function (data) {
            // Get the SVG tag, ignore the rest
            var $svg = $(data).find('svg');

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
              $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
              $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Check if the viewport is set, else we gonna set it if we can.
            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
              $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
            }

            // Replace image with new SVG
            $img.replaceWith($svg);

          }, 'xml');

        });

        //Signup Confirmation Modal
        $('body').addClass("message-sent");
        $("#gform-notification a").click(function () {
          $(".ginput_container").find("input[type=text]").val(""); //reset forms
          
          $("#overlay,#gform-notification").fadeOut("normal", function () {
            $(this).remove();
          });
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
